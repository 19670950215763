exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-[recommendation]-recommendation-tsx": () => import("./../../../src/pages/recommendation/[recommendation].tsx" /* webpackChunkName: "component---src-pages-[recommendation]-recommendation-tsx" */),
  "component---src-pages-[state]-state-tsx": () => import("./../../../src/pages/state/[state].tsx" /* webpackChunkName: "component---src-pages-[state]-state-tsx" */),
  "component---src-pages-[topic]-topic-tsx": () => import("./../../../src/pages/topic/[topic].tsx" /* webpackChunkName: "component---src-pages-[topic]-topic-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-compare-region-key-practice-area-tsx": () => import("./../../../src/pages/compare/[regionKey]/[practiceArea].tsx" /* webpackChunkName: "component---src-pages-compare-region-key-practice-area-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kinship-caregivers-tsx": () => import("./../../../src/pages/kinship-caregivers.tsx" /* webpackChunkName: "component---src-pages-kinship-caregivers-tsx" */),
  "component---src-pages-recommendation-tsx": () => import("./../../../src/pages/recommendation.tsx" /* webpackChunkName: "component---src-pages-recommendation-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-topic-tsx": () => import("./../../../src/pages/topic.tsx" /* webpackChunkName: "component---src-pages-topic-tsx" */)
}

